<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="row">
        <div class="col-50">
          <div class="row mb-3">
            <div class="col-33">
              <a
                  target="_blank"
                  href="https://belregion.ru/"
                  class="footer__logo">
                <img :src="require('@/assets/svg/footer-logo1.svg')" alt="Правительство Белгородской области">
                <span>Правительство Белгородской области</span>
              </a>
            </div>
            <div class="col-33">
              <a
                  target="_blank"
                  href="http://www.xn--31-6kcadhwnl3cfdx.xn--p1ai/"
                  class="footer__logo">
                <img :src="require('@/assets/svg/footer-logo2.svg')" alt="Департамент образования Белгородской области">
                <span>Департамент образования Белгородской области</span>
              </a>
            </div>
            <div class="col-33">
              <a
                  target="_blank"
                  href="https://digital-belregion.ru/"
                  class="footer__logo">
                <img :src="require('@/assets/svg/footer-logo3.svg')" alt="Департамент цифрового развития Белгородской области">
                <span>Департамент цифрового развития Белгородской области</span>
              </a>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-100 footer__logo-title">
              Мы в социальных сетях
            </div>
          </div>
          <div class="row">
            <div class="col-100 social">
              <a target="_blank" href="https://vk.com/regionitcentre">
                <img src="@/assets/img/Vkontakte_32.svg" alt="vk"/>
              </a>
              <a target="_blank" href="https://ok.ru/regionitcentre">
                <img src="@/assets/img/Odnoklassniki_32.svg" alt="ok"/>
              </a>
              <a target="_blank" href="https://www.facebook.com/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%86%D0%B5%D0%BD%D1%82%D1%80-%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D1%8F-%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%B9-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D1%81%D1%80%D0%B5%D0%B4%D1%8B-100637138194253">
                <img src="@/assets/img/Facebook_32.svg" alt="fb"/>
              </a>
              <a target="_blank" href="https://t.me/regionITcentre">
                <img src="@/assets/img/Telegram_32.svg" alt="tg"/>
              </a>
              <a target="_blank" href="https://www.instagram.com/regionitcentre/">
                <img src="@/assets/img/Instagram_32.svg" alt="inst"/>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCMvHK6iz0LURtKj7Vy7-tsg">
                <img src="@/assets/img/Youtube_32.svg" alt="youtube"/>
              </a>
            </div>
          </div>
        </div>

        <div class="col-30">
          <nav class="footer__links">
            <router-link :to="item.link" class="footer__nav-link" v-for="(item, key) in mainSections" :key="key">
              {{ item.text }}
            </router-link>
            <a href="#" class="footer__nav-link footer__nav-link--feedback mt-6">
              Обратная связь
            </a>
          </nav>
        </div>

        <div class="col-20">
          <div class="footer__logo-title">
            Разработчик
          </div>

          <div class="footer__logo-container">
            <Icon xlink="rosatom"
                  viewport="0 0 142 40"
            />
          </div>

<!--          <a href="#" title="Сообщить о проблеме на сайте" class="footer__link">-->
<!--            Сообщить об ошибке-->
<!--          </a>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data: function() {
    return {
      portalSections: [
        {
          link: '#',
          text: 'Детям',
        },
        {
          link: '#',
          text: 'Абитуриентам',
        },
        {
          link: '#',
          text: 'Взрослым',
        },
      ],
      mainSections: [
        {
          link: '#',
          text: 'Каталог образования',
        },
        {
          link: '#',
          text: 'Подборки',
        },
        {
          link: '#',
          text: 'Журнал',
        },
        {
          link: '#',
          text: 'О проекте',
        },
      ],
    }
  },
}
</script>
